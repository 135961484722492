
import {
  defineComponent, ref
} from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmAlert,
    TmButton,
  },
  setup() {
    const showAlert = ref(true)

    return {
      showAlert,
    }
  },
})
