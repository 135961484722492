
import { defineComponent } from 'vue'
import ServicesApiV2Alert from '@/components/pages/services/api/ServicesApiV2Alert.vue'
import ServiceApiSettingsForm from '@/components/pages/services/api/ServiceApiSettingsForm.vue'

export default defineComponent({
  components: {
    ServicesApiV2Alert,
    ServiceApiSettingsForm,
  },
})
